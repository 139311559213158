@font-face {
  font-family: "adobe-garamond-pro";
  src: url("../assets/fonts/adobe-garamond-pro.woff2") format("woff2"),
    url("../assets/fonts/adobe-garamond-pro.woff") format("woff"),
    url("../assets/fonts/adobe-garamond-pro.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0 0 0 0;
  background-color: black;
  height: 100%;
  width: 100%;
}

.bar {
  background-color: white !important;
}

.peg {
  box-shadow: none !important;
}

/* 
::-webkit-scrollbar {
  margin-top: 5em;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

#header {
  position: fixed;
  top: 0;
  height: 2em;
  background-color: #0000008c;
  width: calc(100% - 3em);
  z-index: 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  padding-left: 3em;
  transition: background-color 0.3s ease-in-out;
}

#header h1 {
  font-family: "adobe-garamond-pro";
  font-weight: 400 !important;
  position: fixed;
  left: 50px;
  top: 8px;
}

#header nav {
  position: fixed;
  top: 33px;
  right: 110px;
  display: flex;
  transition: filter 0.4s ease, opacity 0.4s ease;
}

#header nav .home {
  transition: filter 0.4s ease, opacity 0.4s ease;
}

.open #header nav .home {
  opacity: 0.6;
  filter: blur(5px);
  pointer-events: none;
}

#header nav a {
  font-family: "adobe-garamond-pro";
  font-weight: 400 !important;
  font-size: 20px;
  margin-left: 15px;
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 1);
}

#header nav a:hover {
  color: rgba(255, 255, 255, 0.8);
}

#header input {
  display: none;
}

#header label {
  display: none;
}

.welle-l {
  position: fixed;
  height: 30px;
  width: calc(100vw - 120px);
  top: 40px;
  left: 0;
}

.welle-r {
  position: fixed;
  height: 30px;
  width: 120px;
  top: 40px;
  right: 0;
}

.open #header {
  background-color: rgba(0, 0, 0, 0);
}

#header a {
  color: white;
  text-decoration: none;
}

.grid {
  display: grid;
  overflow-x: hidden;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 0;
  max-height: calc(100% - 5em);
  padding-top: 5em;
}

.module {
  height: 100%;
  background-color: black;
  position: relative;
}

.moduleImg {
  width: 100%;
  height: 100%;
  transition: opacity 0.1s ease;
}

.module:hover .moduleImg {
  opacity: 0.5;
}

.moduleLabel {
  opacity: 0;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.module:hover .moduleLabel {
  opacity: 1;
  transform: translateY(-4px);
}

.impressum {
  margin: auto;
  color: white;
}

button,
.button {
  border: none;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 1em;
  margin-top: 1em;
  padding: 15px 20px;
  position: relative;
}

button,
.button {
  background-color: rgb(201, 2, 2);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover,
.button:hover {
  background-color: darkred;
}

button .fas,
.button .fas {
  margin-right: 0.6em;
}

button .dropdown,
.button .dropdown {
  display: flex;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  flex-direction: column;
  top: 48px;
  left: 0;
  width: 200px;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.dropdown a {
  padding: 12px;
  display: block;
  color: black;
  text-decoration: none;
}

.dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

button:focus .dropdown,
.button:focus .dropdown,
.dropdown:hover {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}

@media only screen and (max-width: 40em) {
  .dropdown,
  button:focus .dropdown,
  .button:focus .dropdown,
  .dropdown:hover {
    position: fixed !important;
    height: fit-content;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: auto;
    padding: 10px;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.iframe {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}

/* If Grid is supported, remove the margin we set for the fallback */
@supports (display: grid) {
  .module {
    margin: 0;
  }
}

@media only screen and (max-width: 54em) {
  .page {
    width: calc(100% - 4em);
    margin: 0;
    padding-top: 2em;
    border-radius: 0 !important;
    top: 0 !important;
    bottom: 0;
    height: 100% !important;
  }
}

@media only screen and (max-width: 40em) {
  .page {
    padding-top: 0;
    padding-left: 2em !important;
    padding-right: 2em !important;
  }

  .cover {
    width: 100% !important;
    max-width: 25em !important;
    float: none !important;
    margin-right: calc(100% - 2em) !important;
    margin-bottom: 2em !important;
  }
}

@media only screen and (max-width: 900px) {
  #header nav {
    display: none;
  }

  #header label {
    display: initial;
    color: white;
    position: fixed;
    top: 33px;
    right: 110px;
    cursor: pointer;
  }

  #header input:checked + nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  #header nav label {
    position: fixed;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 2em;
  }

  #header nav a {
    font-size: 1.6em !important;
  }
}

@media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  #header h1 {
    font-size: 1.6em;
  }

  #header {
    height: 1em;
    width: 100%;
  }

  #header .fas {
    margin: 1em;
    font-size: 1.5em;
  }

  .grid,
  #main {
    max-height: calc(100% - 4em) !important;
    padding-top: 4em !important;
  }

  #header h1 {
    font-size: 20px;
    left: 30px;
    top: 9px;
  }

  #header nav {
    position: fixed;
    top: 25px;
    right: 55px;
  }

  #header label {
    top: 25px;
    right: 55px;
  }

  #header nav a {
    font-size: 16px;
    margin-left: 15px;
  }

  .welle-l {
    height: 20px;
    top: 33px;
    width: calc(100vw - 60px);
  }

  .welle-r {
    height: 20px;
    width: 60px;
    top: 33px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
}

table,
th,
td {
  border: 1px solid darkgray;
}

th {
  background-color: #f7f7f7;
}

.banner {
  max-width: initial !important;
  width: 880px;
  margin: -2em -2em 1em -3em;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

i {
  padding-right: 0.5em;
}

#main {
  position: relative;
  overflow: hidden;
  padding-top: 5em;
  width: 100%;
  height: calc(100% - 5em);
}

#home {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: filter 0.4s ease;
  overflow-x: hidden;
  overflow: auto;
}

.open #main #home {
  filter: blur(5px) brightness(30%);
}

#content {
  opacity: 0;
  height: 100%;
  position: relative;
  transform: scale(1.04);
  transition: transform 0.3s ease, opacity 0.3s ease;
  pointer-events: none;
  z-index: 10;
}

#contentinner {
  height: 100%;
  z-index: 10;
}

.open #main #content {
  pointer-events: all;
  opacity: 1;
  transform: scale(1);
}

.page {
  position: absolute;
  top: 2em;
  left: 0;
  right: 0;
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;
  padding: 2em 2em 5em 3em;
  background-color: white;
  min-height: 31em;
  max-height: calc(100% - 8em);
  overflow: auto;
  overflow-x: hidden;
  border-radius: 1em;
  z-index: 10;
}

.page img {
  max-width: 40%;
}

.page img.cover {
  float: left;
  margin-right: 2em;
  box-shadow: 2px 2px 8px #6b6b6b;
}

.page .back {
  cursor: pointer;
  color: #969696;
  display: block;
  margin-bottom: 0.5em;
}

.open #main #content #back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  cursor: inherit;
}

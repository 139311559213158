@font-face {
  font-family: adobe-garamond-pro;
  src: url("adobe-garamond-pro.73802ebc.woff2") format("woff2"), url("adobe-garamond-pro.39666dcf.woff") format("woff"), url("adobe-garamond-pro.e76d5708.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

html {
  height: 100%;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
}

body {
  height: 100%;
  width: 100%;
  background-color: #000;
  margin: 0;
}

.bar {
  background-color: #fff !important;
}

.peg {
  box-shadow: none !important;
}

#header {
  height: 2em;
  width: calc(100% - 3em);
  z-index: 11;
  background-color: rgba(0, 0, 0, .55);
  justify-content: space-between;
  align-items: center;
  padding: 1.5em 1.5em 1.5em 3em;
  transition: background-color .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
}

#header h1 {
  font-family: adobe-garamond-pro;
  position: fixed;
  top: 8px;
  left: 50px;
  font-weight: 400 !important;
}

#header nav {
  transition: -webkit-filter .4s, filter .4s, opacity .4s;
  display: flex;
  position: fixed;
  top: 33px;
  right: 110px;
}

#header nav .home {
  transition: -webkit-filter .4s, filter .4s, opacity .4s;
}

.open #header nav .home {
  opacity: .6;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  pointer-events: none;
}

#header nav a {
  color: #fff;
  margin-left: 15px;
  font-family: adobe-garamond-pro;
  font-size: 20px;
  transition: color .2s;
  font-weight: 400 !important;
}

#header nav a:hover {
  color: rgba(255, 255, 255, .8);
}

#header input, #header label {
  display: none;
}

.welle-l {
  height: 30px;
  width: calc(100vw - 120px);
  position: fixed;
  top: 40px;
  left: 0;
}

.welle-r {
  height: 30px;
  width: 120px;
  position: fixed;
  top: 40px;
  right: 0;
}

.open #header {
  background-color: rgba(0, 0, 0, 0);
}

#header a {
  color: #fff;
  text-decoration: none;
}

.grid {
  grid-gap: 0;
  max-height: calc(100% - 5em);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding-top: 5em;
  display: grid;
  overflow-x: hidden;
}

.module {
  height: 100%;
  background-color: #000;
  position: relative;
}

.moduleImg {
  width: 100%;
  height: 100%;
  transition: opacity .1s;
}

.module:hover .moduleImg {
  opacity: .5;
}

.moduleLabel {
  opacity: 0;
  z-index: 2;
  color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  transition: opacity .2s, transform .2s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.module:hover .moduleLabel {
  opacity: 1;
  transform: translateY(-4px);
}

.impressum {
  color: #fff;
  margin: auto;
}

button, .button {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #c90202;
  border: none;
  border-radius: 8px;
  margin-top: 1em;
  margin-right: 1em;
  padding: 15px 20px;
  font-size: 16px;
  text-decoration: none;
  transition: background-color .2s;
  display: inline-block;
  position: relative;
}

button:hover, .button:hover {
  background-color: #8b0000;
}

button .fas, .button .fas {
  margin-right: .6em;
}

button .dropdown, .button .dropdown {
  opacity: 0;
  pointer-events: none;
  width: 200px;
  background-color: #f1f1f1;
  flex-direction: column;
  transition: opacity .2s, transform .2s;
  display: flex;
  position: absolute;
  top: 48px;
  left: 0;
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, .2);
}

.dropdown a {
  color: #000;
  padding: 12px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: rgba(0, 0, 0, .1);
}

button:focus .dropdown, .button:focus .dropdown, .dropdown:hover {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}

@media only screen and (max-width: 40em) {
  .dropdown, button:focus .dropdown, .button:focus .dropdown, .dropdown:hover {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    padding: 10px;
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.iframe {
  z-index: 10;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
}

@supports (display: grid) {
  .module {
    margin: 0;
  }
}

@media only screen and (max-width: 54em) {
  .page {
    width: calc(100% - 4em);
    margin: 0;
    padding-top: 2em;
    bottom: 0;
    height: 100% !important;
    border-radius: 0 !important;
    top: 0 !important;
  }
}

@media only screen and (max-width: 40em) {
  .page {
    padding-top: 0;
    padding-left: 2em !important;
    padding-right: 2em !important;
  }

  .cover {
    width: 100% !important;
    max-width: 25em !important;
    float: none !important;
    margin-bottom: 2em !important;
    margin-right: calc(100% - 2em) !important;
  }
}

@media only screen and (max-width: 900px) {
  #header nav {
    display: none;
  }

  #header label {
    display: initial;
    color: #fff;
    cursor: pointer;
    position: fixed;
    top: 33px;
    right: 110px;
  }

  #header input:checked + nav {
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(0, 0, 0, .8);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  #header nav label {
    color: #fff;
    font-size: 2em;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  #header nav a {
    font-size: 1.6em !important;
  }
}

@media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  #header h1 {
    font-size: 1.6em;
  }

  #header {
    height: 1em;
    width: 100%;
  }

  #header .fas {
    margin: 1em;
    font-size: 1.5em;
  }

  .grid, #main {
    max-height: calc(100% - 4em) !important;
    padding-top: 4em !important;
  }

  #header h1 {
    font-size: 20px;
    top: 9px;
    left: 30px;
  }

  #header nav {
    position: fixed;
    top: 25px;
    right: 55px;
  }

  #header label {
    top: 25px;
    right: 55px;
  }

  #header nav a {
    margin-left: 15px;
    font-size: 16px;
  }

  .welle-l {
    height: 20px;
    width: calc(100vw - 60px);
    top: 33px;
  }

  .welle-r {
    height: 20px;
    width: 60px;
    top: 33px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 10px;
}

table, th, td {
  border: 1px solid #a9a9a9;
}

th {
  background-color: #f7f7f7;
}

.banner {
  width: 880px;
  margin: -2em -2em 1em -3em;
  max-width: initial !important;
}

.show {
  display: block;
}

i {
  padding-right: .5em;
}

#main {
  width: 100%;
  height: calc(100% - 5em);
  padding-top: 5em;
  position: relative;
  overflow: hidden;
}

#home {
  z-index: 1;
  transition: -webkit-filter .4s, filter .4s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.open #main #home {
  -webkit-filter: blur(5px) brightness(30%);
  filter: blur(5px) brightness(30%);
}

#content {
  opacity: 0;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  transition: transform .3s, opacity .3s;
  position: relative;
  transform: scale(1.04);
}

#contentinner {
  height: 100%;
  z-index: 10;
}

.open #main #content {
  pointer-events: all;
  opacity: 1;
  transform: scale(1);
}

.page {
  max-width: 50em;
  min-height: 31em;
  max-height: calc(100% - 8em);
  z-index: 10;
  background-color: #fff;
  border-radius: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 2em 2em 5em 3em;
  position: absolute;
  top: 2em;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.page img {
  max-width: 40%;
}

.page img.cover {
  float: left;
  margin-right: 2em;
  box-shadow: 2px 2px 8px #6b6b6b;
}

.page .back {
  cursor: pointer;
  color: #969696;
  margin-bottom: .5em;
  display: block;
}

.open #main #content #back {
  z-index: 9;
  cursor: inherit;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/*# sourceMappingURL=embed.142379a7.css.map */
